.centerTableHead{
    .p-column-title{
        margin: auto;
    }
}

.header-dropdown-menu{
    background-color: transparent !important;
    border: none;
    font-weight: 600;
    font-size: 10px;
}

.reconcile-now-btn{
    border-radius: 20px;
    height: 35px;
    font-size: 15px;
    font-weight: 500;
}

.reconciliation-btn{
    font-size: medium !important;
    text-align: center;
}


.mismatch-column {
    overflow-x: scroll;
}

.mismatch-column::-webkit-scrollbar {
    display: none;
}

.mismatch-column {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




.remarks-column {
    min-width: 200px;
}


.remark-item {
    display: flex;
    align-items: center;
}

.remark-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    padding: 0.15em 0.15rem;
    font-size: 10px;
    display: inline-flex;
    // margin-right: .5rem;
    // background-color: #4b9cd8;
    color: #ffffff;
    margin: 2px 5px 2px 1px;
}

.word-break {
    word-break: break-all;
}

.chips{
    .p-chips .p-chips-multiple-container .p-chips-token {
        padding: 0.2145rem 0.429rem;
        margin-right: 0.5rem;
        background: #8d8d8d;
        color: #ffffff;
        border-radius: 15px;
    }
}

.excel_button{
    height: 35px;
    border-radius: 4px;
}

.excel_body{
    border: 2px solid #dbd4d4;
    margin: 30px;
    padding: 15px;
    height: 350px;
}

.recon-refresh-btn {
    margin-right: 10px;
}

.background-color-blue{
    background-color: aliceblue !important;
}

.email-history-search {
    width: 70%;
    display: inline-block;
    margin-right: 10px;    
}

.email-history-dropdown {
    display: inline-block;
}

.to-address-head {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.stripe-column {
    background-color: #f9f9f9;
}

// td:has(span) {
//     background-color: #ffdddd !important;
//     color: #fb0000 !important;
// }

.test-class {
    padding: 0 !important;
}

.status-badge{
    border-radius: 2px;
    padding:  0.15em 0.15rem;
    text-transform: capitalize;
    font-size: 10px;
    letter-spacing: .3px;
    color: #6e5b5b;
    margin-right: 4px;
    margin-bottom: 4px;
    display: inline-block;
  }

.status-matched {
    outline: 1px solid #2eb85c !important;
    color: #2eb85c !important;
}
.status-probable_match {
    outline: 1px solid #4b9cd8 !important;
    color: #4b9cd8 !important;
}
.status-mismatch {
    outline: 1px solid #f9b115 !important;
    color: #f9b115 !important;
}
.status-missing {
    outline: 1px solid #e55353 !important;
    color: #e55353 !important;
}
.status-missing-2b {
    outline: 1px solid #59b3d7 !important;
    color: #59b3d7 !important;
}

.status-missing_in_books {
    outline: 1px solid #e55353 !important;
    color: #e55353 !important;
}
// added for manual matched
.status-manual_matched {
    outline: 1px solid #4b9cd8 !important;
    color: #4b9cd8 !important;
}
.status-missing_in_2a {
    outline: 1px solid #59b3d7 !important;
    color: #59b3d7 !important;
}

.status-missing_in_2b {
    outline: 1px solid #59b3d7 !important;
    color: #59b3d7 !important;
}


.status-Matched {
    outline: 1px solid #2eb85c !important;
    color: #2eb85c !important;
}
.status-Mismatch {
    outline: 1px solid #f9b115 !important;
    color: #f9b115 !important;
}
.status-Probable-Match {
    outline: 1px solid #4b9cd8 !important;
    color: #4b9cd8 !important;
}
.status-Missing-In-Books {
    outline: 1px solid #e55353 !important;
    color: #e55353 !important;
}
.status-Missing-In-2a {
    outline: 1px solid #59b3d7 !important;
    color: #59b3d7 !important;
}
.status-Missing-In-2b {
    outline: 1px solid #59b3d7 !important;
    color: #59b3d7 !important;
}

// added for manual matched
.status-Manual-Matched {
    outline: 1px solid #59b3d7 !important;
    color: #59b3d7 !important;
}
.table-first-head {
    // text-align: center !important;
    display: flex;
    justify-content: center;
}

.diff-value {
    // background-color: #ffdddd;    
    // padding-left: 8px;
    color: #fb0000;
    // display: inline-flex;
    // align-items: center;
    // width: calc(100% + 16px);
    // margin-left: -8px;
    // margin-right: -8px;
    // margin-top: -8px;
    // margin-bottom: -8px;
    // height: calc(100% + 16px);
}

.mail-ico {
    cursor: pointer;
}
/*-------- dark theme -----------*/
// #dark-theme nav.navbar .navbar-brand .logo
// {
//     display: none;
// }
#dark-theme nav.navbar .navbar-nav a
{
    color: #fff !important;
}
#dark-theme .navbar-nav div.dropdown button.text-dark
{
    color: #fff !important;
}
#dark-theme .navbar-nav div.dropdown.show .dropdown-menu.show
{
    background: #191c19 !important;
    -webkit-box-shadow: 0px 0px 35px -3px black;
    -moz-box-shadow: 0px 0px 35px -3px black !important;
    box-shadow: 0px 0px 35px -3px black !important;
}
#dark-theme .navbar-nav .c-header-nav-link.active, .navbar-nav .c-header-nav-link:hover
{
    color: #4b9cd8 !important;
}
#dark-theme nav.navbar
{
    background-color: #191c24 !important;
    
}
#dark-theme main.overflow-content-scroll
{
    background: #000000;
}
#dark-theme .card-deck .card.dashboard-card-hover
{
    background-color: #191c24;
}
#dark-theme .card-columns .card.das-card-header
{
    background-color: #191c24;
}
#dark-theme .company-bg-background
{
    background: #000000;
}
#dark-theme .top-header-dropdown-menu .main-company
{
    color: #fff ;
}
#dark-theme .card.border-0.c-app.flex-row.align-items-center
{
    background: #191c24;
}
#dark-theme .dashboard-card-hover::before, #dark-theme .dashboard-card-hover::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform .3s ease-out 0s;
    background: #191c24;
    content: '';
    pointer-events: none;
}
#dark-theme .btn-group .btn-dark
{
    background-color: #191c24;
    border-color: #ffffff;
    color: #fff;
}
#dark-theme .bg-white-border-bg, #dark-theme .btn-light
{
    background: #000000;
    border-color: #f3f3f3;
    color: #fff !important;
}
#dark-theme .btn-outline-dark
{
    border-color: #fff;
    color: #fff;
}
#dark-theme .card-deck .card.light-login-bg
{
    background: #212121;
    border-right: 0 !important;
}
#dark-theme .card-deck .card h5 , #dark-theme .card-deck .card h6
{
    color: #fff;
}
#dark-theme .dash-date-text
{
    background: #191c24;
    border: #191c24;
    color: #fff;
}
#dark-theme .dash-table-head th
{
    background-color: #181818 !important;
    border-top: 0px solid #555 !important;
    border-bottom: 0px solid #555 !important;
    color: #f9f9f9 !important;
}
#dark-theme .dash-table-td td
{
    background-color: #191c24 !important;
    border-top: 1px solid #191c24 !important;
    border-bottom: 1px solid #2c2e33 !important;
    color: #f9f9f9 !important;
}
#dark-theme .dash-table-td td.text-primary
{
    color: #4b9cd8 !important;
}
#dark-theme .card-header.border-bottom-dash-style
{
    border-bottom: 1px solid #2c2e33 !important;
}
#dark-theme .card .card-header h6
{
    color: #fff;
}
#dark-theme .c-body
{
    background: #000000;
}
#dark-theme .card
{
    background-color:#191c24 !important;
    background: #191c24 !important;
}
#dark-theme .card.no-card-bg
{
    background-color:transparent !important;
}
#dark-theme .reconsile-table-main .p-datatable .p-datatable-thead > tr > th {
    background: #181818;
    border: 1px solid #2c2e33 !important;
    border-left: 0 !important;
    border-top: 0 !important;
}
#dark-theme .reconsile-table-main .p-datatable thead > tr > th {
    color: #fff !important;
    border-color: #2c2e33 !important;
}
#dark-theme .reconsile-table-main .p-datatable tbody > tr > td {
    color: #e1e1e1 !important;
    border-color: #2c2e33 !important;
    border: 1px solid #282828 !important;
    border-top: 0 !important;
    border-left: 0 !important;
}
#dark-theme .reconsile-table-main .p-datatable thead > tr > th:last-child
{
    border-right:0px !important;
}
#dark-theme .reconsile-table-main .p-datatable tbody > tr > td:last-child
{
    border-right:0px !important;
}
#dark-theme .reconsile-table-main tr td.p-frozen-column, #dark-theme .reconsile-table-main tr th.p-frozen-column
{
    background: #121212 !important;
}
#dark-theme  .reconsile-table-main .p-datatable .p-datatable-tbody > tr > td
{
    background: #191c24;
}
#dark-theme .reconsile-table-main .p-datatable .p-datatable-thead > tr:first-child > th
{
    border-top: 1px #2c2e33 solid !important;
}
#dark-theme .radio-new-label .MuiFormControlLabel-label.MuiTypography-body1
{
    color: #fff;
}
#dark-theme h5
{
    color: #fff;
}
#dark-theme .card.tax-border-right
{
    border-right: 0 !important;
}

#dark-theme .gst-p-data-table thead .p-multiselect, #dark-theme .gst-p-data-table thead .p-inputtext {
    border: 1px solid #303030 !important;
    box-shadow: none;
    color: #fff !important;
    background: #191c24 !important;
}
#dark-theme .p-paginator
{
    background: #191c24;
}
#dark-theme .p-paginator .p-paginator-pages .p-paginator-page
{
    color: #e1e1e1;
}
#dark-theme .p-paginator .p-paginator-pages .p-paginator-page.p-highlight
{
    color: #4b9cd8 !important;
    background: #191c24 !important;
}
#dark-theme .p-paginator .p-paginator-pages .p-paginator-page:hover
{
    background: #000;
    color: #fff;
    box-shadow: none;
}
#dark-theme .p-paginator .p-paginator-first:hover, #dark-theme .p-paginator .p-paginator-prev:hover, #dark-theme .p-paginator .p-paginator-next:hover, #dark-theme .p-paginator .p-paginator-last:hover
{
    background: #000;
    color: #fff;
    box-shadow: none;
}
#dark-theme .p-paginator .p-dropdown
{
    background: #191c24;
    color: #fff;
    box-shadow: none;
}
#dark-theme .p-paginator .p-inputtext
{
    color: #fff;
}
#dark-theme .p-paginator .p-paginator-current
{
    color: #e1e1e1;
}
#dark-theme .radio-new-label .MuiSvgIcon-root
{
    color: #fff;
}
#dark-theme .alert-danger {
    background-color: #896c0033;
    border-color: #896c0033;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}
#dark-theme .alert-danger p {
    color: #000 !important;
}
#dark-theme .card-header
{
    background: transparent;
}
#dark-theme .gstr-2b-table thead th
{
    background-color: #181818 !important;
    border-top: 0px solid #555 !important;
    border-bottom: 0px solid #555 !important;
    color: #f9f9f9 !important;
}
#dark-theme .gstr-2b-table tbody td
{
    border-color: #2c2e33 !important;
    color: #f9f9f9 !important;
}
#dark-theme .gstr-2b-table thead th.white-th-bg-clr
{
    background-color: #191c24 !important;
    border-top: 1px solid #2c2e33 !important;
}
#dark-theme .gstr-2b-table thead .bg-light-warning {
    background-color: #181818 !important;
    color: #f5f5f5 !important;
}
#dark-theme .bold-label span.MuiFormControlLabel-label
{
    color: #fff;
}
#dark-theme .MuiRadio-colorPrimary span.MuiIconButton-label
{
    color: #fff !important;
}
#dark-theme .gst-finsights-table .p-datatable .p-datatable-thead > tr > th
{
    background-color: #181818 !important;
    border-top: 1px solid #2c2e33 !important;
    border-bottom: 0px solid #555 !important;
    color: #f9f9f9 !important;
}
#dark-theme .gst-finsights-table .p-datatable .p-datatable-tbody > tr > td
{
    border-color: #2c2e33 !important;
    color: #f9f9f9 !important;
    background-color: #191c24 !important;
    border-style: solid !important;
}
#dark-theme .card h6.bold-label
{
    color: #fff;
}
#dark-theme .card .text-center p
{
    color: #fff;
}
#dark-theme .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
{
    background: #191c24 !important;
    border: 1px solid #4e9cd8 !important;
    color: #4b9cd8 !important;
}

#dark-theme .p-tabview.horizontal-tabs-style .p-tabview-nav li .p-tabview-nav-link ,#dark-theme .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    margin: inherit;
    border-color: #4e9cd8;
    border: 1px solid white;
    color: #f9f9f9;
    background: #000;
}
#dark-theme .datatable-filter-demo .p-progressbar
{
    background-color: #000000;
}
#dark-theme .datatable-filter-demo .p-progressbar .p-progressbar-value
{
    background-color: #f0b118d1;
}
#dark-theme .p-progressbar .p-progressbar-label
{
    color: #fff;
}
#dark-theme .dropdown .dropdown-item:hover
{
    background: #000;
    color: #4b9cd8 !important;
}
#dark-theme .dropdown-item.active, .dropdown-item:active
{
    background: #191c24 !important;
}
#dark-theme .card-columns .card.das-card-header .btn-dark
{
    color: #fff;
    background-color: #000000;
    border-color: #ffffff;
}
#dark-theme .fas.fa-chevron-circle-right.dash-i
{
    color: #000;
}
#dark-theme table.light-login-bg.inside-table-last-tr
{
    background: #181818;
}
#dark-theme .btn-dark
{
    background-color: #000;
    border-color: #ffffff;
}
#dark-theme .header-dropdown-pop-inside.profile-modal.right-animation .modal .modal-content
{
    background-color: #191c24;
    border-color: #4b9cd8;
}
#dark-theme .header-dropdown-pop-inside.profile-modal.right-animation .btn-dark
{
    background-color: #191c24 !important;
    border-color: #5e5e5e;
    color: #d1d0d0;
    line-height: 2.5;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
}
#dark-theme .header-dropdown-pop-inside.profile-modal.right-animation .btn-dark:hover
{
    color: #4b9cd8 !important;
}
#dark-theme .header-dropdown-pop-inside.profile-modal.right-animation .profile-right-icon {
    right: 12px;
    top: 8px;
}
#dark-theme .header-dropdown-pop-inside.profile-modal.right-animation h6
{
    color: #fff;
}
#dark-theme .modal h6.text-dark.font-size-14
{
    color: #fff !important;
}
#dark-theme .modal .modal-header
{
    background: #191c24;
    border-color: #262626;
    border-radius: 0;
}
#dark-theme .modal .modal-content
{
    background-color: #000;
}
#dark-theme .modal .modal-body.light-login-bg
{
    background: #000;
}
#dark-theme .modal .modal-body.light-login-bg .form-control
{
    background-color: #000;
    border-color: #444;
}
#dark-theme .modal .modal-body.light-login-bg button.rounded-pil.btn-primary
{
    background-color: #191c19 !important;
    border-color: #4b9cd8 !important;
    border-width: 2px;
}
#dark-theme header.modal-header
{
    color: #fff;
}
#dark-theme .modal-body p
{
    color: #fff;
}
#dark-theme button:disabled
{
    cursor: not-allowed;
    opacity: 0.4;
}

#dark-theme .tabpanel-zero.bg-white
{
    background-color: #191c24 !important;
   
}
#dark-theme .p-tabview .p-tabview-panels
{
    background-color: #191c24;
   
}
#dark-theme .card-header.dashed-border-bottom
{
    border-bottom: 1px solid #323232;
}
#dark-theme .card .card-body label
{
    color: #fff;
}
#dark-theme .card .card-body h6
{
    color: #fff;
}
#dark-theme  .p-dialog .p-dialog-header 
{
    background: #191c24 !important;
    border-color: #262626 !important;
}
#dark-theme  .p-dialog .p-dialog-header 
{
    background: #191c24 !important;
    border-color: #262626 !important;
}
#dark-theme .dialog-modal-style-white .p-dialog-content {
    background: #000000;
}
#dark-theme .card.five-px-border {
    border: 5px solid #191c24 !important;
}
#dark-theme p.status-font.font-size-18.mb-3
{
    color: #fff;
}
#dark-theme .max-container-width p.font-size-12.bold-label
{
    color: #fff;
}
#dark-theme .email-left-scroll-height p
{
    color: #fff;
}
#dark-theme .email-left-scroll-height .background-color-blue
{
    background-color: #000 !important;
}
#dark-theme .card p
{
    color: #fff;
}
#dark-theme .das-card-header .card-deck .card.border-0.mdb-shado.mb-3.tax-border-right.light-login-bg 
{
    border: 1px solid #2c2e33 !important;
}
#dark-theme .das-card-header .card-deck .card.border-0.mdb-shado.tax-border-right.light-login-bg 
{
    border: 1px solid #2c2e33 !important;
}
#dark-theme .p-dialog .p-dialog-header .p-dialog-title
{
    color: #fff;
}
#dark-theme .modal .modal-body .light-login-bg
{
    background: #000 ;
}
#dark-theme .tab-content > .tab-pane
{
    background-color: #191c24 !important;
}
#dark-theme .tab-content  label
{
    color: #fff !important;
}
#dark-theme .tab-content .form-control
{
    background-color: #191c24;
    border-color: #444;
}    
#dark-theme .verticals-tabs .nav-pills-custom .nav-link
{
    background: #191c24 !important;
    color: #fff !important;
}
#dark-theme .verticals-tabs .nav-pills-custom .nav-link::before
{
    border-left: 10px solid #191c24 !important;
}
#dark-theme .form-control:disabled, .form-control[readonly]
{
    background-color: #2c2c2c;
}
#dark-theme .hr-light-border
{
    border-top: 1px solid #444;
}
#dark-theme .early-access-text
{
    color: #fff;
}
#dark-theme .price-value
{
    color: #fff;
}
#dark-theme .main-check-light
{
    background: #000;
    color: #fff;
}
#dark-theme .modal-body label
{
    color: #fff;
}
#dark-theme table.credit-debit-table thead th
{
    background-color: #181818 !important;
    border-top: 0px solid #555 !important;
    border-bottom: 0px solid #555 !important;
    color: #f9f9f9 !important;
    border-right: 0 !important;
    border-left: 0 !important;
}
#dark-theme table.credit-debit-table tbody td {
    background-color: #191c24 !important;
    border-top: 1px solid #191c24 !important;
    border-bottom: 1px solid #2c2e33 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    color: #f9f9f9 !important;
}
#dark-theme table.credit-debit-table tbody td button.btn-danger
{
    background-color: #000;
}
#dark-theme .att-feed div
{
    background-color: transparent !important;
    border-color: #444 !important;
}
#dark-theme .modal-backdrop.fade.show
{
    background: #555555;
}
#dark-theme .btn-primary
{
    background-color: #000000 !important;
}
#dark-theme .modal-header button.close
{
    color: #fff;
}
#dark-theme .user-sec-dp .p-dropdown
{
    background: #000000 !important;
}
#dark-theme .user-sec-dp .p-dropdown .p-dropdown-label
{
    color: #fff !important;
}
#dark-theme .p-dropdown-panel.p-component.p-connected-overlay-enter-done
{
    background: #000 !important;
    color: #fff !important;
}
#dark-theme .p-dropdown-panel .p-dropdown-header
{
    background: #000;
}
#dark-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-item
{
    color: #fff !important;
    font-size: 12px;
}
#dark-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover
{
    background: #191c24 !important;
}
#dark-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight
{
    background: #191c24;
}
#dark-theme .p-dropdown-filter-container .p-dropdown-filter.p-inputtext.p-component
{
    background: #000;
    color: #fff;
}
#dark-theme .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message
{
    color: #fff;
}
#dark-theme .input-fields .p-inputtext
{
    background: #000;
    color: #fff;
}
#dark-theme .p-column-filter-overlay
{
    background: #000;
}
#dark-theme .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item
{
    color: #fff;
}
#dark-theme .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:hover
{
    background: #191c24;
}
#dark-theme .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight
{
    background: #191c24 !important;
}
#dark-theme .gst-p-data-table .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover
{
    background: #000;
    box-shadow: none;
}
#dark-theme .p-datepicker, #dark-theme .p-datepicker-header
{
    background: #000;
    color: #fff;
}
#dark-theme .p-datepicker .p-datepicker-prev.p-link, #dark-theme .p-datepicker .p-datepicker-next.p-link, #dark-theme .p-datepicker .p-datepicker-title
{
    color: #fff;
}
#dark-theme .p-monthpicker .p-monthpicker-month:hover
{
    background: #191c24;
    color: #fff;
}
#dark-theme .p-multiselect-panel
{
    background: #000;
}
#dark-theme .p-multiselect-panel .p-multiselect-items .p-multiselect-item
{
    color: #fff;
}
#dark-theme .p-multiselect-panel .p-multiselect-items .p-multiselect-item:hover
{
    color: #fff;
    background: #191c24;
}
#dark-theme .p-multiselect-panel .p-multiselect-header
{
    background: #000;
    color: #fff;
}
#dark-theme .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight
{
    background: #191c24;
    color: #fff;
    box-shadow: none;
}
#dark-theme span.btn-group .btn-white
{
    background-color: #000000 !important;
    border-color: #ffffff;
    color: #fff;
}
#dark-theme span.btn-group .btn-primary
{
    background-color: #191c24 !important;
    color: #fff;
    border-color: #fff !important;
}
#dark-theme .accordion.accordion-icons .card
{
    background: #000 !important;
    border: 1px solid #555 !important;
    color: #fff;
}
#dark-theme .accordion.accordion-icons .card .card-header
{
    background-color: #191c24 !important;
    color: #fff;
}
#dark-theme .p-dialog .p-dialog-header .p-dialog-header-icon
{
    color: #fff;
    box-shadow: none;
}
#dark-theme .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover
{
    color: #fff;
    box-shadow: none;
}
#dark-theme .p-dialog .p-dialog-content
{
    background: #000;
    color: #fff;
}
#dark-theme .p-dialog .p-dialog-footer
{
    background: #191c24;
}
#dark-theme .p-component-overlay
{
    background: #fffefe3b !important;
}
#dark-theme .p-dialog .p-dialog-footer
{
    border-top: #555;
}
#dark-theme .card.message-body-border-dashed textarea
{
    background: #191c24;
    color: #fff;
    border-color: #555;
}
#dark-theme .hr-light-border-compose
{
    border-color: #555;
}
#dark-theme .message-body-border-dashed
{
    border-color: #555 !important;
}
#dark-theme .card.message-body-border-dashed table .MuiTableRow-root td
{
    background-color: #181818 !important;
    border-top: 0px solid #555 !important;
    border-bottom: 0px solid #555 !important;
    color: #f9f9f9 !important;
    border-right: 0 !important;
    border-left: 0 !important;
}
#dark-theme .p-dialog .card .mobile-no-inp
{
    background: #000;
    border-color: #555;
    color: #fff;
}
#dark-theme .p-dialog-content .card .mt-1.tax-border-right
{
    border-right: 1px solid #555 !important;
}
#dark-theme .textinput-email-chips.chips .p-chips .p-chips-multiple-container input
{
    color: #fff;
}
#dark-theme .dark-theme-if-enable .dark-theme-if-enable-card .light-login-bg
{
    background-color: #000 !important;
}
#dark-theme .dark-theme-if-enable table tr td
{
    background-color: #181818 !important;
    border-top: 0px solid #555 !important;
    border-bottom: 0px solid #555 !important;
    color: #f9f9f9 !important;
    border-right: 0 !important;
    border-left: 0 !important;
}
#dark-theme .dark-theme-if-enable .light-five-nine-border
{
    border-color: #444444;
}
#dark-theme .mail-border-bottom-line
{
    border-color: #444 !important;
}
#dark-theme .light-login-bg-border
{
    background: #191c24;
    color: #fff;
    border-color: #eee;
}
#dark-theme .diff-value
{
    color: #f55656;
}
#dark-theme td.reconsile-multi-size .p-multiselect
{
    background: #000;
}
#dark-theme .invite-dark-theme-user 
{
    background: #000;
}
#dark-theme .invite-dark-theme-user .form-control
{
    background: #000;
    border-color: #555;
    color: #fff;
}
#dark-theme .step-header
{
    border: 1px solid #000 !important;
}
#dark-theme .filing-return-year .dropdown-menu.show
{
    background-color: #000;
}
#dark-theme .filing-return-year .dropdown-item
{
    color: #fff;
}
#dark-theme .text-info-new
{
    color: #59b3d7 !important;
}
#dark-theme .contacts-table-dt td input[type=text]
{
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: #ffffff;
    background: #121212;
    border: 1px solid #555555;
}
#dark-theme #main-warning-error #faq .card .card-header .btn-header-link
{
    background-color: #191c24 !important;
}
#dark-theme #main-warning-error #faq .card .card-body.bg-danger-light p
{
    color: #000 !important;
}
#dark-theme .overlaypanel-demo
{
    background-color: #191c24 !important;
    box-shadow: 0 0.125rem 0.25rem #8181812e !important;
}
#dark-theme .overlaypanel-demo .p-overlaypanel-content .card
{
    background: #000 !important;
}
#dark-theme .overlaypanel-demo .p-overlaypanel-content .card .card-body .normal-demo-text
{
    color: #fff !important;
}
#dark-theme .overlaypanel-demo .p-overlaypanel-content span
{
    color: #fff !important;
}
#dark-theme .overlaypanel-demo .p-overlaypanel-content span:first-child
{
    color: #4b9cd8 !important;
}
#dark-theme .p-overlaypanel:before, #dark-theme .p-overlaypanel:after
{
    border-bottom-color: #191c24 !important;
}
#dark-theme .month-container-style
{
    border: 1px solid #191c24 !important;
}
#dark-theme .p-datepicker
{
    border: 1px solid #191c24 !important;
}
#dark-theme .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight
{
    background: #000;
    border: 1px solid #4b9cd8;
}
#dark-theme .dialog-modal-style-white .p-dialog-content input.form-control.height-40
{
    background: #191c24 !important;
    border: 1px solid #555 !important;
}
#dark-theme .email-history-dark-mode .light-login-bg
{
    background: #000;
}
#dark-theme .email-history-dark-mode .p-datatable .p-datatable-thead > tr > th
{
    background: #181818;
    color: #fff;
}
#dark-theme .email-history-dark-mode .p-datatable .p-datatable-thead > tr:first-child > th
{
    border-bottom: 0;
}
#dark-theme .email-history-dark-mode .p-datatable .p-datatable-tbody > tr > td
{
    background-color: #191c24 !important;
    border: 1px solid #2c2e33 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}
#dark-theme .reconsile-multi-size .p-multiselect
{
    background: #000;
    box-shadow: none;
}
#dark-theme .steps-multiselect .p-dropdown
{
    background: #000;
}
#dark-theme .pan-gst-border
{
  border: 1px solid #a3a3a3;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
#dark-theme .gst-pan-btn
{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
  background-color: #000 !important;
  border-color: #4b9cd8 !important;
}
#dark-theme .gst-pan-btn:not(:disabled):not(.disabled):active, .show > #dark-theme .gst-pan-btn.dropdown-toggle
{
  color: #fff !important;
}
/*--------- dark theme --------------*/